body {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f9f9;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-bolder {
    font-weight: 700;
}

.text-boldest {
    font-weight: 900;
}

.color-customBlue {
    color: #000000 !important;
}

.color-customBlueLight {
    color: #000000 !important;
}

.color-customBlueLighter {
    color: #000000;
}

.bg-custom {
    background-color: #000000 !important;
}

.btn:hover {
    background-color: #434343 !important;
}

.bg-skyBlue {
    background-color: skyblue !important;
}

.align-self-center {
    align-self: center;
}

.s-logo {
    width: 40px;
    background-color: #071b4b;
    height: 40px;
    text-align: center;
    padding-top: -15px;
    font-size: 28px;
    border-radius: 10px;
}

.navLink {
    min-width: 100px;
}

.bg-progress-progCustomBlue {
    background-color: #071b4b !important;
    /* color: white; */
}

.neomorphIconCard {
    width: 150px;
    height: 150px;
    border-radius: 20px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
    -20px -20px 60px #ffffff;
}

.skillsImg {
    width: 70px;
    transition: width 0.15s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

.skillsImg:hover {
    width: 80px;
    filter: none;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

/* Projects page */
.proj-image {
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
}

.proj-image:hover {
    opacity: .8;
}

/* Contact page */
.contact-btns span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}

.contact-btns:hover span {
    max-width: 7rem;
    /* padding-left: 5px; */
}

.contact-btns:hover span::before {
    content: "|";
    padding-left: 5px;
    padding-right: 5px;
    color: #ffffffbd;
}
